.p-shadow-1 {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.p-shadow-2 {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.p-shadow-3 {
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.3);
}

.p-shadow-4 {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.2);
}

.p-to-shadow-4,
.p-to-shadow-3,
.p-to-shadow-2,
.p-to-shadow-1 {
  transition-timing-function: ease;
  transition: box-shadow 0.5s;
}

.p-to-shadow-1:hover {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.p-to-shadow-2:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.p-to-shadow-3:hover {
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.3);
}

.p-to-shadow-4:hover {
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.2);
}
